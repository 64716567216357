import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../home.css"; // Assuming home.css is in the same directory as your component
import headimage1 from "../resource/headimage1.jpg"; // Make sure the path is correct
import headimage2 from "../resource/headimage2.jpg"; // Make sure the path is correct
import headimage3 from "../resource/headimage3.jpg"; // Make sure the path is correct
import headimage4 from "../resource/headimage4.jpg"; // Make sure the path is correct
import instalogo from "../resource/instalogo.jpg"; // Make sure the path is correct
import beadslogo from "../resource/beadslogo.jpg"; // Make sure the path is correct
import whatsapplogo from "../resource/whatsapplogo.jpg"; // Make sure the path is correct
import custsat from "../resource/custsat.jpg"; // Make sure the path is correct
import timelydelivery from "../resource/timelydelivery.jpg"; // Make sure the path is correct
import onlineshop from "../resource/onlineshop.jpg"; // Make sure the path is correct

function Home() {
  // State to track whether to show the button
  const [shouldShowButton, setShouldShowButton] = useState(false);

  // Function to scroll to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Effect to toggle the visibility of the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setShouldShowButton(true);
      } else {
        setShouldShowButton(false);
      }
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        {" "}
        {/* Remove padding and use full width */}
        <div className="position-relative">
          <div
            id="carouselExampleAutoplaying"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active " data-bs-interval="2000">
                <img
                  src={headimage1}
                  className="d-block w-100"
                  alt="First Slide"
                />
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img
                  src={headimage2}
                  className="d-block w-100"
                  alt="Second Slide"
                />
              </div>
              <div className="carousel-item" data-bs-interval="2000">
                <img
                  src={headimage3}
                  className="d-block w-100"
                  alt="Third Slide"
                />
              </div>
              <div className="carousel-item">
                <img
                  src={headimage4}
                  className="d-block w-100"
                  alt="Third Slide"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          <div className="carousel-caption-container">
            <div className="carousel-caption">
              <h1 className="carousel-heading">
                Looking for Natural Gemstones for <br /> Jewellery
                Manufacturing?
              </h1>
              <p className="carousel-text">
                Harsh Gems Jewellery can be your Trustworthy <br />
                Source for Reliable Gemstone Brilliance
              </p>
              <a href="#contactUsdiv" className="btn btn-info btn-lg">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className="container-fluid justify-content-center"
        style={{ backgroundColor: "#f0f0f0", paddingTop: "50px" }}
      >
        <div className="row d-flex justify-content-center">
          <h1
            className="text-center h1kwdd"
            style={{
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 500,
            }}
          >
            Know What We Do
          </h1>
          <div className="horizontal-line"></div>
        </div>
        <div className="row d-flex justify-content-center">
          <p
            className="text-center pkwdd"
            style={{
              fontFamily: "Roboto Condensed, sans-serif",
              // fontWeight: "light",
              color: "black",
            }}
          >
            We at Harsh Gems Jewellery, supply Natural Precious Gemstones like
            Emerald, Ruby, Sapphire as well as <br /> Semi-precious like Blue
            Topaz, Amethyst, Citrine, Garnet, Peridot, Opal, Aquamarine,
            Morganite, Tanzanite, Tourmalines.
          </p>
        </div>
        <div class="row justify-content-center">
          {/* <!-- First Row of Cards --> */}
          <div class="col-md-4 d-flex justify-content-center p-3">
            <div
              class="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={custsat}
                class="card-img-top"
                alt="Customer Satisfaction"
              />
              <div class="card-body">
                <h5 class="card-title text-center">Customer Satisfaction</h5>
                <p class="card-text text-center">
                  Responsive and attentive customer service to address inquiries
                  and concerns promptly.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-center p-3">
            <div
              class="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={timelydelivery}
                class="card-img-top"
                alt="Timely Delivery"
              />
              <div class="card-body">
                {/* <h5 class="card-title text-center">Timely Delivery</h5> */}
                <p class="card-text text-center">
                  Timely dispatch and delivery, meeting your deadlines with
                  precision.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-center p-3">
            <div
              class="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={headimage3}
                class="card-img-top"
                alt="Quality Assurance"
              />
              <div class="card-body">
                <h5 class="card-title text-center">Quality Assurance</h5>
                <p class="card-text text-center">
                  Quality control measures to ensure that each order of gemstone
                  meets the quality standards.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* second row of cards */}
        <div class="row justify-content-center">
          <div className="col-md-4 d-flex justify-content-center p-3">
            <div
              className="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={headimage4}
                className="card-img-top"
                alt="Diverse Gemstones Variety"
              />
              <div className="card-body">
                <h5 className="card-title text-center">
                  Diverse Gemstones Variety
                </h5>
                <p className="card-text text-center">
                  Extensive collection from timeless classics like emeralds,
                  rubies, and sapphires to a captivating array of semi-precious
                  stones.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center p-3">
            <div
              className="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={headimage1}
                className="card-img-top"
                alt="Online Shop"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Online Shop</h5>
                <p className="card-text text-center">
                  Through our website, we make our gemstones available for
                  purchase in many countries.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center p-3">
            <div
              className="card shadow"
              style={{ width: "20rem", height: "20rem" }}
            >
              <img
                src={headimage2}
                className="card-img-top"
                alt="Authenticity"
              />
              <div className="card-body">
                <h5 className="card-title text-center">Authenticity</h5>
                <p className="card-text text-center">
                  Clear and transparent communication regarding the
                  characteristics of each gemstone.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row d-flex justify-content-center">
          <h1
            className="text-center"
            style={{
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 500,
              marginTop: "1%",
            }}
          >
            Our Collection
          </h1>
          <div className="horizontal-line"></div>
        </div>

        <div
          style={{
            margin: "3.5%",
            marginTop: "0.5%",
            padding: "2%",
            borderRadius: "10px",
          }}
        >
          {/* Row 1 */}
          <div
            className="row shadow custom-row"
            style={{
              padding: "3%",
              backgroundColor: "white",
              borderRadius: "10px",
              marginBottom: "2%",
            }}
          >
            <div className="col-md-6 order-md-1 custom-col">
              <img src={headimage3} className="img-fluid" alt="Image 1" />
            </div>
            <div className="col-md-6 order-md-2 custom-text">
              <h2
                className="text-center"
                style={{ fontFamily: "Roboto Condensed, sans-serif" }}
              >
                Precious Stones
              </h2>
              <p
                className="text-center"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  lineHeight: "2",
                }}
              >
                We offer an exquisite selection of precious gemstones: Diamonds,
                known for their unmatched brilliance and durability; Rubies,
                celebrated for their deep, vibrant reds; Sapphires, available in
                a spectrum of colors with exceptional hardness; and Emeralds,
                prized for their rich, vivid green hues, symbolizing elegance
                and opulence.
              </p>
              <br />
              <div className="text-center">
                <a
                  href="https://shop.harshgems.com/collections/catalog"
                  target="_blank"
                >
                  <button className="btn btn-secondary">Learn More</button>
                </a>
              </div>
            </div>
          </div>

          {/* Row 2 - Reversed on Desktop */}
          <div
            className="row shadow custom-row"
            style={{
              padding: "3%",
              backgroundColor: "white",
              borderRadius: "10px",
              marginBottom: "2%",
            }}
          >
            <div className="col-md-6 order-md-2 custom-col">
              <img src={headimage4} className="img-fluid" alt="Image 2" />
            </div>
            <div className="col-md-6 order-md-1 custom-text">
              {/* Title and Text for Row 2 */}
              <h2
                className="text-center"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  // lineHeight: "2",
                }}
              >
                Semi Precious Stones
              </h2>
              <p
                className="text-center"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  lineHeight: "2",
                }}
              >
                Our collection includes a diverse range of semi-precious stones:
                Amethyst, known for its striking purple color; Aquamarine, with
                its tranquil blue hues; Citrine, radiating warm yellow to orange
                tones; Garnet, in deep reds and greens; Peridot, with its
                distinctive olive green; and Topaz, available in various colors.
              </p>
              <br />
              <div className="text-center">
                <a
                  href="https://shop.harshgems.com/collections/catalog"
                  target="_blank"
                >
                  <button className="btn btn-secondary">Learn More</button>
                </a>
              </div>
            </div>
          </div>

          {/* Row 3 */}
          <div
            className="row shadow custom-row"
            style={{
              padding: "3%",
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <div className="col-md-6 order-md-1 custom-col d-flex justify-content-center">
              <img
                src={beadslogo}
                className="img-fluid"
                alt="Image 3"
                style={{ height: "300px" }}
              />
            </div>
            <div className="col-md-6 order-md-2 custom-text">
              {/* Title and Text for Row 3 */}
              <h2
                className="text-center"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  // lineHeight: "2",
                }}
              >
                Beads
              </h2>
              <p
                className="text-center"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  lineHeight: "2",
                }}
              >
                Our bead assortment features a vibrant variety: Glass Beads, in
                a rainbow of colors and finishes; Wooden Beads, offering natural
                textures and earthy tones; Metal Beads, for a sleek, modern
                touch; Ceramic Beads, with artisanal charm; and Plastic Beads,
                in playful shapes and bright hues, perfect for creative
                crafting.
              </p>
              <br />
              <div className="text-center">
                <a
                  href="https://shop.harshgems.com/collections/catalog"
                  target="_blank"
                >
                  <button className="btn btn-secondary">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="contactUsdiv"
          className="shadow"
          style={{
            margin: "3%",
            // marginLeft: "3%",
            // marginRight: "3%",
            padding: "5%",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          <div className="row">
            {/* Column for Contact Information */}
            <div className="col-md-6">
              <h2>Contact Us</h2>
              <h4>Address: </h4>
              <p>
                Al Khor Street, Gold Souq Deira, Dubai, United Arab Emirates
                <br />
                <br />
                <h4>Phone: </h4>
                +971 56 163 1100 <br /> +971 56 6787706
                <br />
                <br />
                <h4>Email: </h4>
                <p>info@harshgems.com</p>
                <br />
                <br />
                <h4>Socials:</h4>
                <a
                  href="https://www.instagram.com/harshgems"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={instalogo}
                    alt="Instagram"
                    style={{ width: "30px", height: "auto" }}
                  />
                </a>
                <a
                  href="your-whatsapp-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={whatsapplogo}
                    alt="WhatsApp"
                    style={{ width: "30px", height: "auto" }}
                  />
                </a>
              </p>
            </div>

            {/* Column for Google Map */}
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.965751212357!2d55.29538997472956!3d25.2717375286788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f434476c1a8d7%3A0xaf2d8112f3fe0986!2sHarsh%20Gems%20Jewellery%20Trading%20L.L.C.!5e0!3m2!1sen!2sae!4v1703336102292!5m2!1sen!2sae"
                width="100%"
                height="450"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {shouldShowButton && (
        <button
          id="goUpBtn"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            padding: "12px 25px",
            backgroundColor: "#A0A0A0", // Gray color
            color: "white",
            border: "1px solid #888",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
            transition: "background-color 0.3s, box-shadow 0.3s",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#B0B0B0")} // Lighter gray on hover
          onMouseOut={(e) => (e.target.style.backgroundColor = "#A0A0A0")} // Original color on mouse out
        >
          Go Up
        </button>
      )}
    </>
  );
}

export default Home;
