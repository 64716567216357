import React from "react";
import instalogo from "../resource/instalogo.jpg"; // Make sure the path is correct
import whatsapplogo from "../resource/whatsapplogo.jpg"; // Make sure the path is correct
function AboutUs() {
  return (
    <>
      <div
        class="container py-5 text-center"
        style={{
          padding: "0 10% 0 10%",
          fontFamily: "Roboto Condensed, sans-serif",
          // fontWeight: "light",
          color: "black",
        }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="text-center mb-4">About Harsh Gems Jewellery</h1>
            <p>
              Welcome to Harsh Gems Jewellery, your trusted destination for
              exquisite gemstones nestled in the heart of Dubai's Gold Souq. At
              Harsh Gems, we take pride in being a leading supplier of both
              precious and semi-precious gemstones, serving the needs of
              discerning jewellery manufacturers and individuals seeking the
              finest loose gemstones.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-5 mb-3">Meet Our Founder</h2>
            <p>
              Mr. Vijay Prakash, the visionary founder of Harsh Gems Jewellery.
              With over two decades of invaluable experience in the gemstone
              industry, Mr. Prakash embarked on a journey to establish a company
              that transcends boundaries and brings the beauty of gemstones to
              global markets. His vision encompasses not only expanding our
              customer base but also providing an extensive variety of
              high-quality gemstones, ensuring unparalleled customer
              satisfaction.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-5 mb-3">Our Commitment</h2>
            <p>
              At Harsh Gems Jewellery, our values are rooted in the satisfaction
              of our customers. We believe in building lasting relationships by
              being connected to our clientele. We take the time to listen,
              learn, and understand the unique needs and preferences of each
              customer. Our commitment is to do the right thing, ensuring that
              every requirement is met with precision and excellence.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-5 mb-3">Dedication to Quality and Timely Delivery</h2>
            <p>
              Our commitment to excellence is reflected in the numerous positive
              reviews we've received regarding the quality of our goods, prompt
              delivery times, and the exceptional level of service and
              communication. We take pride in our ability to supply stones in
              every conceivable shape, size, and cut, catering to your specific
              requirements, no matter the quantity. Harsh Gems is your go-to
              source for top-notch gemstones that transcend expectations.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-5 mb-3">Global Reach</h2>
            <p>
              Situated in the heart of the Gold Souq in Dubai, Our strategic
              location not only embraces the local charm but also propels us to
              trade seamlessly on a global scale. Our gemstones not only reflect
              the fine craftsmanship but also carry the essence of the vibrant
              and diverse culture that Dubai embodies. From our hub in the Gold
              Souq, we proudly engage in international trade, ensuring our
              gemstones become cherished pieces worldwide.
            </p>
          </div>
        </div>
      </div>
      <div
        className="shadow"
        style={{
          margin: "3%",
          // marginLeft: "3%",
          // marginRight: "3%",
          padding: "5%",
          backgroundColor: "white",
          borderRadius: "12px",
        }}
      >
        <div className="row">
          {/* Column for Contact Information */}
          <div className="col-md-6">
            <h2>Contact Us</h2>
            <h4>Address: </h4>
            <p>
              Al Khor Street, Gold Souq Deira, Dubai, United Arab Emirates
              <br />
              <br />
              <h4>Phone: </h4>
              +971 56 163 1100 <br /> +971 56 6787706
              <br />
              <br />
              <h4>Email: </h4>
              <p>info@harshgems.com</p>
              <br />
              <br />
              <h4>Socials:</h4>
              <a
                href="https://www.instagram.com/harshgems"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instalogo}
                  alt="Instagram"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
              <a
                href="your-whatsapp-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapplogo}
                  alt="WhatsApp"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
            </p>
          </div>

          {/* Column for Google Map */}
          <div className="col-md-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.965751212357!2d55.29538997472956!3d25.2717375286788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f434476c1a8d7%3A0xaf2d8112f3fe0986!2sHarsh%20Gems%20Jewellery%20Trading%20L.L.C.!5e0!3m2!1sen!2sae!4v1703336102292!5m2!1sen!2sae"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
