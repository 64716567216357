import React, { useEffect } from "react";
import logoImage from "../resource/companylogo.jpg"; // Importing the logo image
import { Link } from "react-router-dom";
import "../Navbar.css";

function Navbar() {
  const closeNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");

    if (!navbarToggler.classList.contains("collapsed")) {
      navbarToggler.click();
    }
  };
  useEffect(() => {
    const dropdownSubmenus = document.querySelectorAll(".dropdown-submenu");

    dropdownSubmenus.forEach((submenu) => {
      submenu.addEventListener("mouseenter", handleMouseEnter);
      submenu.addEventListener("mouseleave", handleMouseLeave);
    });

    function handleMouseEnter() {
      this.querySelector(".dropdown-menu").classList.add("show");
    }

    function handleMouseLeave() {
      this.querySelector(".dropdown-menu").classList.remove("show");
    }

    return () => {
      dropdownSubmenus.forEach((submenu) => {
        submenu.removeEventListener("mouseenter", handleMouseEnter);
        submenu.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg sticky-top"
      style={{
        paddingLeft: "3%",
        paddingRight: "3%",
        backgroundColor: "#f0f0f0",
      }}
    >
      <div className="container-fluid">
        <Link
          to="/"
          className="navbar-brand"
          style={{
            fontFamily: "'Lato', sans-serif",
            fontWeight: 700,
            color: "#003366",
            fontSize: "20px",
          }}
          onClick={closeNavbar}
        >
          <img
            src={logoImage}
            alt="Logo"
            style={{ marginRight: "10px", width: "50px", height: "auto" }}
          />
          Harsh Gems Jewellery
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={closeNavbar}>
                Home
              </Link>{" "}
              {/* Added Home link */}
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="http://shop.harshgems.com"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeNavbar}
              >
                Shop
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // onClick={closeNavbar}
              >
                Gemstones
              </a>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/emerald"
                    onClick={closeNavbar}
                  >
                    Emerald
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/ruby"
                    onClick={closeNavbar}
                  >
                    Ruby
                  </Link>
                </li>

                {/* Nested dropdown for Sapphire */}
                <li class="dropdown-submenu">
                  <a
                    class="dropdown-toggle dropdown-item"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={closeNavbar}
                  >
                    Sapphire
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/bluesapphire"
                        onClick={closeNavbar}
                      >
                        Blue
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/yellowsapphire"
                        onClick={closeNavbar}
                      >
                        Yellow
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/pinksapphire"
                        onClick={closeNavbar}
                      >
                        Pink
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="multisapphire"
                        onClick={closeNavbar}
                      >
                        Multi
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Nested dropdown for Topaz */}
                <li className="dropdown-submenu">
                  <a
                    class="dropdown-toggle dropdown-item"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={closeNavbar}
                  >
                    Topaz
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/skytopaz"
                        onClick={closeNavbar}
                      >
                        Sky Topaz
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/swisstopaz"
                        onClick={closeNavbar}
                      >
                        Swiss
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/londontopaz"
                        onClick={closeNavbar}
                      >
                        London
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* Nested dropdown for Garnet */}
                <li className="dropdown-submenu">
                  <a
                    class="dropdown-toggle dropdown-item"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    // onClick={closeNavbar}
                  >
                    Garnet
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/garnet"
                        onClick={closeNavbar}
                      >
                        Garnet
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Nested dropdown for Quartz */}
                <li className="dropdown-submenu">
                  <a
                    class="dropdown-toggle dropdown-item"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={closeNavbar}
                  >
                    Quartz
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/amethyst"
                        onClick={closeNavbar}
                      >
                        Amethyst
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/citrine"
                        onClick={closeNavbar}
                      >
                        Citrine
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* Nested dropdown for Tourmaline */}

                {/* ... Continue with similar nested structures for Garnet, Quartz, Tourmaline ... */}

                <li>
                  <Link
                    className="dropdown-item"
                    to="/aquamarine"
                    onClick={closeNavbar}
                  >
                    Aquamarine
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="morganite"
                    onClick={closeNavbar}
                  >
                    Morganite
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/peridot"
                    onClick={closeNavbar}
                  >
                    Peridot
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" href="OtherStones.js">
                    Other Stones
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/about-us" onClick={closeNavbar}>
                About Us
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/contact-us" onClick={closeNavbar}>
                Contact Us
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
