import React from "react";

function ContactUs() {
  return (
    <div className="container mt-5">
      <h1>Contact Us</h1>
      <p>If you have any questions or inquiries, please contact us:</p>
      <form>
        {/* Form elements like input fields, textarea, etc. */}
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactUs;
