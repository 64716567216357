import React from "react";
import "../Emerald.css"; // Make sure to create this CSS file
import headimage1 from "../resource/headimage1.jpg";
import headimage2 from "../resource/headimage2.jpg";
import headimage3 from "../resource/headimage3.jpg";
import headimage4 from "../resource/headimage4.jpg";
import oval from "../resource/ovalshape.jpg";
import pear from "../resource/pearshape.jpg";
import octagon from "../resource/octagonshape.jpg";
import marquise from "../resource/marquiseshape.jpg";
import baguette from "../resource/baguetteshape.jpg";
import round from "../resource/roundshape.jpg";
import emeralddp from "../resource/emeralddp.jpg";

const stockItems = [
  {
    shape: "Oval",
    img: oval,
    sizes: "4x3, 5x3, 5x4, 6x4, 7x5, 8x6, 9x7, Free-Sizes",
  },
  {
    shape: "Pear",
    img: pear,
    sizes: "4x3, 5x3, 5x4, 6x4, 7x5, 8x6, 9x7, Free-Sizes",
  },
  {
    shape: "Round",
    img: round,
    sizes: "calibration From 1.5 to 5mm and Free-Sizes",
  },
  {
    shape: "Octagon",
    img: octagon,
    sizes: "4x3, 5x3, 6x4, 7x5, 8x6, 9x7, Free-Sizes",
  },
  {
    shape: "Marquis",
    img: marquise,
    sizes: "4x2, 2.5x5, 6x3, 7x3.5, 8x4, Free-Sizes",
  },
  {
    shape: "Baguette",
    img: baguette,
    sizes: "3x2, 3.5x2, 4x2, 2.5x5, Free-Sizes",
  },
  // Add more shapes as needed
];

const Emerald = () => {
  return (
    <div style={{ backgroundColor: "#f0f0f0" }}>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <h1 className="text-black text-center display-4">
                Discover the Alluring Beauty of Emeralds
              </h1>
            </div>
          </div>
        </div>
      </section>
      {/* Information Section */}
      <section className="info-section py-5">
        <div
          className="container p-4"
          style={{ backgroundColor: "white", borderRadius: "12px" }}
        >
          <div className="row">
            <div className="col-md-6">
              <h2
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: 900,
                }}
              >
                Emerald
              </h2>
              <p>
                Emeralds are known for their rich green color and have been
                cherished throughout history. They are often associated with
                royalty and luxury. The intense green color of emeralds is due
                to the presence of chromium and sometimes vanadium.
              </p>
              <p>
                These gemstones are found in various parts of the world, with
                major mines located in Colombia, Brazil, and Zambia. Each source
                contributes unique characteristics to the emeralds it produces.
              </p>
              {/* Add more paragraphs or bullet points as needed */}
              {/* <section className="identification-facts py-5">
                <div className="container"> */}
              <h2
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: 900,
                }}
              >
                Emerald Identification Facts
              </h2>
              <div className="row">
                <div className="col-md-6 col-lg-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h6 className="card-title">Mineral</h6>
                      <p className="card-text">Beryl</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h6 className="card-title">Color</h6>
                      <p className="card-text">Green</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h6 className="card-title">Refractive Index</h6>
                      <p className="card-text">1.577 to 1.583</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h6 className="card-title">Mohs Hardness</h6>
                      <p className="card-text">7.5 to 8</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>
              </section> */}
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "30%" }}>
                {" "}
                {/* Adjust this value as needed */}
                <img
                  src={emeralddp}
                  alt="Blue Sapphire"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Stock Availability Section */}
      <section className="stock-available">
        <div className="container my-0">
          <h2
            className="text-center"
            style={{
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 900,
            }}
          >
            Stock Available
          </h2>
          <div className="row justify-content-center">
            {stockItems.map((item) => (
              <div
                key={item.shape}
                className="col-md-6 col-lg-4 mb-4 d-flex justify-content-center text-center"
              >
                <div className="card" style={{ width: "75%" }}>
                  {" "}
                  {/* Inline style for width */}
                  <img
                    src={item.img}
                    alt={item.shape}
                    className="card-img-top img-fit"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{item.shape}</h5>
                    <p className="card-text">Available sizes - {item.sizes}</p>
                    <a
                      href="https://shop.harshgems.com/search?q=Emerald&options%5Bprefix%5D=last"
                      target="_blank"
                      className="btn btn-secondary"
                    >
                      View Details
                    </a>
                    {/* Replace '#' in href with your link */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Inquiry Information Section */}
      <section className="inquiry-section">
        <div className="container">
          <h2
            className="text-center"
            style={{
              fontFamily: "Roboto Condensed, sans-serif",
              fontWeight: 900,
            }}
          >
            Have a Query or Looking for Specific Gemstones?
          </h2>
          <div
            className="row  p-4"
            style={{ backgroundColor: "white", borderRadius: "12px" }}
          >
            <div className="col-md-6">
              {/* Bootstrap Carousel */}
              <div
                id="collectionCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={headimage1}
                      className="d-block w-100"
                      alt="Image 1"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={headimage2}
                      className="d-block w-100"
                      alt="Image 2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={headimage3}
                      className="d-block w-100"
                      alt="Image 3"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={headimage4}
                      className="d-block w-100"
                      alt="Image 4"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#collectionCarousel"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#collectionCarousel"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>

              <h3
                className="my-3"
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: 900,
                }}
              >
                Explore Our Collection
              </h3>
              <p className="my-0">
                Visit our online shop to explore our wide range of gemstones and
                find the perfect one for you.
              </p>
              <a
                href="https://shop.harshgems.com"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary my-4"
              >
                Visit Shop
              </a>
            </div>
            <div className="col-md-6">
              <h3
                style={{
                  fontFamily: "Roboto Condensed, sans-serif",
                  fontWeight: 900,
                }}
              >
                Send Us an Inquiry
              </h3>
              <form>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    placeholder="Your Inquiry..."
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit Inquiry
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Emerald;
