import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import AboutUs from "./Pages/Aboutus";
import ContactUs from "./Pages/Contactus";
import Emerald from "./Pages/Emerald";
import Aquamarine from "./Pages/Aquamarine";
import Amethyst from "./Pages/Amethyst";
import BearQuartz from "./Pages/BearQuartz";
import BlueSapphire from "./Pages/BlueSapphire";
import Citrine from "./Pages/Citrine";
import CrystalQuartz from "./Pages/CrystalQuartz";
import Garnet from "./Pages/Garnet";
import GreenTourmaline from "./Pages/GreenTourmaline";
import Hessonite from "./Pages/Hessonite";
import LondonTopaz from "./Pages/LondonTopaz";
import Morganite from "./Pages/Morganite";
import MultiSapphire from "./Pages/MultiSapphire";
import MultiTourmaline from "./Pages/MultiTourmaline";
import MysticTopaz from "./Pages/MysticTopaz";
import Peridot from "./Pages/Peridot";
import PinkSapphire from "./Pages/PinkSapphire";
import PinkTourmaline from "./Pages/PinkTourmaline";
import Rhodolite from "./Pages/Rhodolite";
import RoseQuartz from "./Pages/RoseQuartz";
import Ruby from "./Pages/Ruby";
import Rubylite from "./Pages/Rubylite";
import SkyTopaz from "./Pages/SkyTopaz";
import SmokyQuartz from "./Pages/SmokyQuartz";
import SwissTopaz from "./Pages/SwissTopaz";
import Tsavorite from "./Pages/Tsavorite";
import WhiteTopaz from "./Pages/WhiteTopaz";
import YellowSapphire from "./Pages/YellowSapphire";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/emerald" element={<Emerald />} />
        <Route path="/aquamarine" element={<Aquamarine />} />
        <Route path="/amethyst" element={<Amethyst />} />
        {/* <Route path="/bearquartz" element={<BearQuartz />} /> */}
        <Route path="/bluesapphire" element={<BlueSapphire />} />
        <Route path="/citrine" element={<Citrine />} />
        {/* <Route path="/crystalquartz" element={<CrystalQuartz />} /> */}
        <Route path="/garnet" element={<Garnet />} />
        {/* <Route path="/greentourmaline" element={<GreenTourmaline />} /> */}
        {/* <Route path="/hessonite" element={<Hessonite />} /> */}
        <Route path="/londontopaz" element={<LondonTopaz />} />
        <Route path="/morganite" element={<Morganite />} />
        <Route path="/multisapphire" element={<MultiSapphire />} />
        {/* <Route path="/multitourmaline" element={<MultiTourmaline />} /> */}
        {/* <Route path="/mystictopaz" element={<MysticTopaz />} /> */}
        <Route path="/peridot" element={<Peridot />} />
        <Route path="/pinksapphire" element={<PinkSapphire />} />
        {/* <Route path="/pinktourmaline" element={<PinkTourmaline />} /> */}
        {/* <Route path="/rhodolite" element={<Rhodolite />} /> */}
        {/* <Route path="/rosequartz" element={<RoseQuartz />} /> */}
        <Route path="/ruby" element={<Ruby />} />
        {/* <Route path="/rubylite" element={<Rubylite />} /> */}
        <Route path="/skytopaz" element={<SkyTopaz />} />
        {/* <Route path="/smokyquartz" element={<SmokyQuartz />} /> */}
        <Route path="/swisstopaz" element={<SwissTopaz />} />
        {/* <Route path="/tsavorite" element={<Tsavorite />} /> */}
        {/* <Route path="/whitetopaz" element={<WhiteTopaz />} /> */}
        <Route path="/yellowsapphire" element={<YellowSapphire />} />
      </Routes>
    </Router>
  );
}

export default App;
